export const CSSCommon = {
    'color': {
        'hover': '#9cd392',
        'black': '#131313',
        'blackout': 'rgba(0, 0, 0, 0.6)',
        'green': '#007d40',
        'grey': '#e6e6e6',
        'greyBorder': '#e5e5e5',
        'white': '#ffffff',
        'yellow': '#ffc400',
        'h1':'#656565',
        'warning': '#ffbc00',
        'error': '#e54040',
        'info': '#2472bf'
    },
    'fontFamily': 'Arial, Helvetica, sans-serif',
    'fontSize': {
        'h1': '1.8rem',
        'h2': '1.8rem',
        'h3': '1.6rem',
        'h4': '1.4rem',
        'h5': '1.2rem',
        'h6': '1rem',
        'button': '1.6rem',
        'input': '2rem'
    },
    'fontWeight': {
        'normal': '300',
        'medium': '500',
        'heavy': '900'
    },
    'border': {
        'normal': 'solid 0.1rem',
        'bold': 'solid 0.2rem'
    },
    'borderRadius': {
        'input': '1.2rem'
    },
    'margin': {
        'normal': '1rem'
    },
    'padding': {
        "mobileMin": '1rem',
        "tabletMin": '5rem'
    },
    'transition': '.1s',
    'scrollTop': '50',
    'maxRowWidth': '32.4rem',
    'maxTotalWidth': '70.8rem',
    //Change both mediaBreakpoints & mediaBreakpointsInt simultaniously!
    'mediaBreakpoints': {
        "mobileMin": 'min-width: 420px',
        "mobileMax": 'max-width: 767px',
        "tabletMin": 'min-width: 768px',
        "tabletMax": 'max-width: 914px',
        "desktopMin": 'min-width: 1015px'
    },
    'mediaBreakpointsInt': {
        "mobileMin": 420,
        "mobileMax": 767,
        "tabletMin": 768,
        "tabletMax": 914,
        "desktopMin": 1015
    }
};